// src/main.ts

import "core-js/stable";
import "regenerator-runtime/runtime";
import "./services/filters.ts";
import "./styles/style.css";
import "vue-datetime/dist/vue-datetime.css";

// Import the factory function, not the router instance
import { createRouter, getRouter } from "./router";

import App from "./app.vue";
import { Datetime } from "vue-datetime";
import IdleVue from "idle-vue";
import VJsoneditor from "v-jsoneditor/src/index";
import Vue from "vue";
import VueApollo from "vue-apollo";
import { apolloProvider } from "./services/vue-apollo";
import { initializeAuth } from "./services/auth"; // Import the initialization function for auth
import { setGlobalConfig } from "./config/config.js";
import snackbarPlugin from "./plugins/snackbar";
import store from "./store/store";
import vuetify from "./plugins/vuetify";

let config: any = null;

// Async function to load configuration
export const loadConfig = async () => {
  console.log("LOAD CONFIG");
  try {
    const response = await fetch("/config/config.json");
    console.log("response", response);
    if (!response.ok) {
      throw new Error(`Get config error: ${response.status}`);
    }
    config = await response.json();
    console.log("config", config);
    setGlobalConfig(config);
  } catch (error) {
    console.error("Failed to load config:", error);
    throw error; // Re-throw to prevent app initialization
  }
};

// Initialize the Vue application
const initializeApp = async () => {
  try {
    await loadConfig(); // Ensure config is loaded first
    const router = createRouter(); // Create the router after config is loaded
    initializeAuth(); // Initialize Cognito Auth with the router instance
  } catch (error) {
    console.error("Failed to initialize the app:", error);
    // Optionally, handle config load failure (e.g., show an error page)
    // For example, you can display an error message to the user
    const loading = document.getElementById("loading");
    if (loading) {
      loading.innerHTML =
        "<h2>Failed to load configuration. Please try again later.</h2>";
    }
    return;
  }

  Vue.config.productionTip = false;

  const eventsHub = new Vue();

  // Install Vue plugins
  Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    idleTime: 1000 * 60 * 15, // 15 minutes
    store: store,
    startAtIdle: false,
  });
  Vue.use(VueApollo);
  Vue.use(VJsoneditor); // https://www.npmjs.com/package/v-jsoneditor
  Vue.use(snackbarPlugin, { store });

  // Register global components
  Vue.component("datetime-selector", Datetime);

  // Initialize and mount the Vue instance
  new Vue({
    router: getRouter(), // Use the initialized router instance
    vuetify,
    store: store,
    apolloProvider: apolloProvider,
    render: (h) => h(App),
  }).$mount("#app");

  // Remove loading indicator if you have one
  const loading = document.getElementById("loading");
  if (loading) {
    loading.style.display = "none";
  }
};

console.log("RUN");

initializeApp();
