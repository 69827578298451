import VueRouter, { RouteConfig } from "vue-router";

import ActivityLogsView from "../views/admin-activity-logs.vue";
import Auth from "../services/auth";
import AuthGuard from "../services/auth-guard";
import BasestationView from "../views/admin-base-station-files.vue";
import BulkProcessing from "../views/admin-bulk-processing.vue";
import DeviceAssignmentView from "../views/admin-device-assignment.vue";
import FullViewer from "../views/full-viewer.vue";
import ImportedScansView from "../views/admin-imported-scans.vue";
import LayersView from "../views/admin-layers.vue";
import LogoutSuccess from "../components/logout-success.vue";
import OrganizationView from "../views/admin-organizations.vue";
import PackageUploadView from "../views/package-upload.vue";
import PipelineManagementView from "../views/admin-pdal-pipelines.vue";
import ProjectsView from "../views/admin-projects.vue";
import TrajectoryProcessingView from "../views/admin-trajectory-processing.vue";
import UsersView from "../views/admin-users.vue";
import Viewer from "../views/scan-viewer.vue";
import Vue from "vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/login",
    name: "Login",
    beforeEnter(/* _to, _from, _next */) {
      Auth.login();
    },
  },
  {
    path: "/login/oauth2/code/cognito",
    beforeEnter(/* _to, _from, next */) {
      Auth.parseCognitoWebResponse(window.location.href);
    },
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: LogoutSuccess,
    beforeEnter(_to, _from, next) {
      console.log("not auth");
      next();
    },
  },
  {
    path: "/",
    name: "Viewer",
    meta: {
      menu: true,
      title: "Scans",
      icon: "mdi-map-legend",
      userTypes: ["Admin", "Power User", "Standard"],
      projectSelector: true,
    },
    component: Viewer,
    beforeEnter: AuthGuard.canActivate,
  },
  {
    path: "/full-viewer",
    name: "Full Viewer",
    meta: {
      menu: true,
      title: "Scans (Full Viewer)",
      icon: "mdi-map",
      userTypes: ["Admin"],
      projectSelector: true,
    },
    component: FullViewer,
    beforeEnter: AuthGuard.canActivate,
  },
  {
    path: "/layers",
    name: "Layers",
    meta: {
      menu: true,
      title: "Layers",
      icon: "mdi-layers-outline",
      userTypes: ["Admin", "Power User"],
      projectSelector: true,
    },
    component: LayersView,
    beforeEnter: AuthGuard.canActivate,
  },
  {
    path: "/pipeline-management",
    name: "PipelineManagement",
    meta: {
      menu: true,
      title: "Pipeline Management",
      icon: "mdi-pipe",
      userTypes: ["Admin", "Power User"],
      projectSelector: true,
    },
    component: PipelineManagementView,
    beforeEnter: AuthGuard.canActivate,
  },
  {
    path: "/imported-scans",
    name: "Imported Scans",
    meta: {
      menu: true,
      title: "Imported Scans",
      icon: "mdi-cloud-upload-outline",
      userTypes: ["Admin", "Power User"],
      projectSelector: true,
    },
    component: ImportedScansView,
    beforeEnter: AuthGuard.canActivate,
  },
  {
    path: "/basestation",
    name: "Basestation",
    meta: {
      menu: true,
      title: "Basestation Files",
      icon: "mdi-video-input-antenna",
      userTypes: ["Admin", "Power User"],
      projectSelector: true,
    },
    component: BasestationView,
    beforeEnter: AuthGuard.canActivate,
  },
  {
    path: "/trajectory-processing",
    name: "TrajectoryProcessing",
    meta: {
      menu: true,
      title: "Trajectory Processing",
      icon: "mdi-map-marker-path",
      userTypes: ["Admin", "Power User"],
      projectSelector: true,
    },
    component: TrajectoryProcessingView,
    beforeEnter: AuthGuard.canActivate,
  },
  {
    path: "/bulk-processing",
    name: "Bulk Processing",
    meta: {
      menu: true,
      title: "Bulk Processing",
      icon: "mdi-cloud-sync-outline",
      userTypes: ["Admin", "Power User"],
      projectSelector: true,
    },
    component: BulkProcessing,
    beforeEnter: AuthGuard.canActivate,
  },
  {
    path: "/device-assignment",
    name: "DeviceAssignment",
    meta: {
      menu: true,
      title: "Device Assignment",
      icon: "mdi-book-clock-outline",
      userTypes: ["Admin", "Power User"],
    },
    component: DeviceAssignmentView,
    beforeEnter: AuthGuard.canActivate,
  },
  {
    path: "/projects",
    name: "Projects",
    meta: {
      menu: true,
      title: "Projects",
      icon: "mdi-notebook-outline",
      userTypes: ["Admin", "Power User"],
    },
    component: ProjectsView,
    beforeEnter: AuthGuard.canActivate,
  },
  {
    path: "/organizations",
    name: "Organization",
    meta: {
      menu: true,
      title: "Organizations",
      icon: "mdi-home-city-outline",
      userTypes: ["Admin"],
    },
    component: OrganizationView,
    beforeEnter: AuthGuard.canActivate,
  },
  {
    path: "/users",
    name: "Users",
    meta: {
      menu: true,
      title: "Users",
      icon: "mdi-account-multiple",
      userTypes: ["Admin", "Power User"],
    },
    component: UsersView,
    beforeEnter: AuthGuard.canActivate,
  },
  {
    path: "/activity-logs",
    name: "ActivityLogs",
    meta: {
      menu: true,
      title: "Activity Logs",
      icon: "mdi-book-open-variant",
      userTypes: ["Admin", "Power User"],
    },
    component: ActivityLogsView,
    beforeEnter: AuthGuard.canActivate,
  },
  {
    path: "/package-upload",
    name: "PackageUpload",
    meta: {
      menu: true,
      title: "Package Upload",
      icon: "mdi-file-upload-outline",
      userTypes: [],
    },
    component: PackageUploadView,
    beforeEnter: AuthGuard.canActivate,
  },
  {
    path: "/logout",
    name: "Logout",
    meta: {
      menu: true,
      title: "Logout",
      icon: "mdi-account",
      userTypes: [],
    },
    component: LogoutSuccess,
    beforeEnter(_to, _from, next) {
      Auth.logout();
      console.log("successfully logged out");
      next();
    },
  },
];

let routerInstance: VueRouter | null = null;

export const createRouter = (): VueRouter => {
  const router = new VueRouter({
    mode: "history",
    base: process.env.VUE_APP_BASE_URL,
    routes,
  });

  routerInstance = router;
  return router;
};

export const getRouter = (): VueRouter => {
  if (!routerInstance) {
    throw new Error("Router has not been created yet.");
  }
  return routerInstance;
};

export default createRouter;
